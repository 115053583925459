import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { selectTruck, setTrucks } from './Actions';
import { showMessage } from '../message/Actions';
import { fetch, integerValue } from '../utils';


export const TruckSelect = (properties) => {
  const props = properties;

  let truckSelect = null;

  const [loading, setLoading] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [gettingTrucks, setGettingTrucks] = useState();
  const [networkError, setNetworkError] = useState();

  useEffect(() => {
    if (props.all) {
      getTrucks();
      return;
    }

    if (props.selectedConstructionSite != null) {
      getTrucks(props.selectedConstructionSite.get('id'));
    }
  }, [])

  useEffect(() => {
    if (props.all) {
      return;
    }

    if (props.selectedConstructionSite == null) {
      props.setTrucks([]);
      props.selectTruck(null);
      return;
    }
  }, [props.selectedContract])

  useEffect(() => {
    if (props.selectedConstructionSite == null) {
      props.setTrucks([]);
      props.selectTruck(null);
      return;
    }
    getTrucks(props.selectedConstructionSite.get('id'));
  }, [props.selectedConstructionSite])

  const changeTruck = (event) => {
    const value = integerValue(event.target.value, null);
    const truck = props.trucks.find(truck => truck.get('id') === value);
    props.selectTruck(truck);
    localStorage.truck = value;
  }

  const getTrucks = (site) => {
    if (firstTime) {
      setLoading(true);
      setFirstTime(false);
    }

    setGettingTrucks(true);

    let url = '/trucks?site=' + site;

    if (props.all) {
      url = '/trucks/';
    }

    fetch(url)
      .then(data => {
        setNetworkError(false);

        if (data != null) {
          props.setTrucks(data);

          if (truckSelect) {
            truckSelect.click();
          }

          if (typeof (Storage) !== 'undefined') {
            const value = integerValue(localStorage.truck, null);

            if (value == null) return;

            const truck = props.trucks.find(truck => truck.get('id') === value);
            props.selectTruck(truck);
          }
        }
      })
      .catch(error => {
        setNetworkError(true);
      })
      .then(data => {
        setLoading(false);
        setGettingTrucks(false);
      });
  }

  if (loading) return <div className='loader'></div>;

  return (
    <div>
      {networkError ? '(Ei ajan tasainen)' : ''}
      <select id='truck' ref={element => truckSelect = element}
        className={props.required && props.selectedTruck == null ? 'required' : ''}
        onChange={changeTruck.bind(this)}
        value={props.selectedTruck != null && props.selectedTruck.get('id') || ''}
        required={props.required}
        disabled={!props.all && props.selectedConstructionSite == null ? true : false}
        onClick={props.selectedConstructionSite != null && !gettingTrucks ?
          getTrucks.bind(null, props.selectedConstructionSite.get('id')) : null}>
        <option value='' onClick={(e) => { e.stopPropagation(); }}>
          Valitse rekka
        </option>
        {
          props.trucks.map(truck => (
            <option key={truck.get('id')} value={truck.get('id')}
              onClick={(e) => { e.stopPropagation(); }}>
              {truck.get('register_number')}
            </option>
          ))
        }
      </select>
    </div>
  );
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  trucks: state.truckSelect.get('trucks'),
  selectedTruck: state.truckSelect.get('selectedTruck'),
}), { selectTruck, setTrucks, showMessage })(TruckSelect);
