import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { clearEarthworks, addEarthworks, removeEarthwork, changeEarthwork, addEarthwork } from './Actions';
import DataField from '../../../../src/dataField/DataField.js';
import { showNotice } from '../../../../src/notice/Actions';
import { showConfirm } from '../../../../src/confirm/Actions';
import { showMessage } from '../../../../src/message/Actions';
import { selectTruck } from '../../../../src/truckSelect/Actions';
import TruckSelect from '../../../../src/truckSelect/TruckSelect.js';
import TopContractAndSiteSelect from '../../../../src/topContractAndSiteSelect/TopContractAndSiteSelect.js'
import {
  fetch, integerValue, Socket, timer, toETRSTM35FIN, testIsValidRoadInfo, paddedNumber, stateValueParser,
  getRoadData
} from '../utils.js';
import { AccuracyFixer, SetBankingName } from './Components';
import ChangeEarthworkView from './Components';
import './Earthwork.css';



const View = props => {
  if (props.view === 0) {
    if (props.earthworks.last() == null || props.lastBankLatitude ||
      props.lastBankingLatitude) {
      return (
        <LoadInput changeState={props.changeState}
          location_road_number={props.location_road_number}
          road={props.road} roadPart={props.roadPart}
          roadDistance={props.roadDistance}
          onLoad={props.onLoad}
          mass={props.mass}
          attentions={props.attentions}
          loadingLoad={props.loadingLoad}
          autoGPS={props.autoGPS}
          dailyCount={props.dailyCount}
          dailyMass={props.dailyMass}
          store={props.store}
        />
      );
    }

    return (
      <BankInput changeState={props.changeState}
        road={props.road} roadPart={props.roadPart}
        roadDistance={props.roadDistance}
        toggleSetBanking={props.toggleSetBanking}
        onBank={props.onBank}
        loadingBank={props.loadingBank}
        autoGPS={props.autoGPS}
        lastLoadPart={props.lastLoadPart}
        lastLoadDistance={props.lastLoadDistance}
        lastMass={props.lastMass}
        lastTruck={props.lastTruck}
        dailyCount={props.dailyCount}
        dailyMass={props.dailyMass}
      />
    );
  }
  else {
    return (
      <div>
        <TrackingInfo changeState={props.changeState} earthworks={props.earthworks}
          site={props.selectedConstructionSite} dailyMass={props.dailyMass}
          wholeMass={props.wholeMass}
          loading={props.loadingEarthworks}
          removeEarthwork={props.confirmRemoveEarthwork}
          removeLocalEarthwork={props.confirmRemoveLocalEarthwork}
          roadNumber={props.location_road_number} roadPart={props.location_road_part}
          roadPole={props.location_road_pole} accuracy={props.accuracy}
          time={props.locationTime} changeEarthwork={props.changeEarthwork} />
      </div>
    );
  }
};

const LoadInput = props => {
  let road = props.road;

  if (road == null) {
    road = props.location_road_number;
  }

  return (
    <fieldset>
      <legend>
        <h4>Kuormatiedot</h4>
      </legend>
      <div className='row borders'>
        <div className='column'>
          <label htmlFor='road'>Tie</label>
          <input id='road' type='text' value={road || '-'} readOnly />
        </div>
        <div className='column'>
          <label htmlFor='roadPart'>Tieosa</label>
          Edellinen: {props.lastPart || '-'}
          <input id='roadPart' type='tel'
            value={props.roadPart}
            onChange={props.changeState.bind(this, 'roadPart', 'integer', 0)}
            readOnly={props.autoGPS}
            required />
        </div>
        <div className='column'>
          <label htmlFor='pole'>Paalu</label>
          Edellinen: {props.lastPole || '-'}
          <input id='pole' type='tel'
            value={props.roadDistance}
            onChange={props.changeState.bind(this, 'roadDistance', 'integer', 0)}
            readOnly={props.autoGPS}
            required />
          <label className='checkbox'>
            Automattinen GPS
            <input type='checkbox'
              onChange={props.changeState.bind(this, 'autoGPS', 'boolean', true)}
              checked={props.autoGPS} />
          </label>
        </div>
      </div>
      <div className='row borders'>
        <div className='column'>
          <label htmlFor='truck'>Rekka</label>
          Päivän kuormat: {props.dailyCount || '-'} kpl
          <TruckSelect required store={props.store} />
        </div>
        <div className='column'>
          <label htmlFor='mass'>Kuorma (Tonnit) </label>
          Päivän: {props.dailyMass || '-'} tonnia
          <input id='mass' type='tel'
            value={props.mass}
            onChange={props.changeState.bind(this, 'mass', 'float', 0.0)} required />
        </div>
      </div>
      <label htmlFor='attentions'>Huomiot</label>
      <input id='attentions' type='text' value={props.attentions || ''}
        onChange={props.changeState.bind(this, 'attentions', 'string', '')} />
      {props.loadingLoad ? <div className='loader float-left' /> :
        <button onClick={props.onLoad}>Lastaa</button>
      }
    </fieldset>
  );
};

const BankInput = props => {
  return (
    <fieldset>
      <legend>
        <h4>Kippaustiedot</h4>
      </legend>
      <div className='row borders'>
        <div className='column'>
          <label htmlFor='loadRoadPart'>Lastaus tieosa</label>
          <input id='loadRoadPart' type='tel'
            value={props.lastLoadPart}
            readOnly />
        </div>
        <div className='column'>
          <label htmlFor='loadRoadDistance'>Lastaus paalu</label>
          <input id='loadRoadDistance' type='tel'
            value={props.lastLoadDistance}
            readOnly />
        </div>
        <div className='column'>
          <label htmlFor='truck'>Rekka</label>
          Päivän kuormat: {props.dailyCount || '-'} kpl
          <input id='mass' type='text'
            value={props.lastTruck}
            readOnly />
        </div>
        <div className='column'>
          <label htmlFor='mass'>Kuorma (Tonnit) </label>
          Päivän: {props.dailyMass || '-'} tonnia
          <input id='mass' type='tel'
            value={props.lastMass}
            readOnly />
        </div>
      </div>
      <div className='row borders'>
        <div className='column'>
          <label htmlFor='road'>Tie</label>
          <input id='road' type='text' value={props.road || ''} readOnly />
        </div>
        <div className='column'>
          <label htmlFor='roadPart'>Tieosa</label>
          Edellinen: {props.lastPart || '-'}
          <input id='roadPart' type='tel'
            value={props.roadPart}
            onChange={props.changeState.bind(this, 'roadPart', 'integer', 0)}
            readOnly={props.autoGPS}
            required />
        </div>
        <div className='column'>
          <label htmlFor='pole'>Paalu</label>
          Edellinen: {props.lastDistance || '-'}
          <input id='pole' type='tel'
            value={props.roadDistance}
            onChange={props.changeState.bind(this, 'roadDistance', 'integer', 0)}
            readOnly={props.autoGPS}
            required />
          <label className='checkbox'>
            Automattinen GPS
            <input type='checkbox'
              onChange={props.changeState.bind(this, 'autoGPS', 'boolean', true)}
              checked={props.autoGPS} />
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='column center'>
          <button onClick={props.toggleSetBanking}>Kippaa läjitys</button>
        </div>
        <div className='column center'>
          {props.loadingBank ?
            <div className='loader float-left' /> : <button onClick={props.onBank}>Kippaa penger</button>
          }
        </div>
      </div>
    </fieldset>
  );
};

const CurrentData = props => {
  return (
    <div>
      Päivän käytetty massa: <strong>{Math.round(props.dailyMass * 100) / 100 || '-'}</strong> tonnia
      <br />
      Kohteen käytetty massa: <strong>{Math.round(props.wholeMass * 100) / 100 || '-'}</strong> tonnia
    </div>
  );
}

const TrackingInfo = props => {
  return (
    <fieldset>
      <legend>
        <h4>Seurantatiedot</h4>
      </legend>
      <FiveLastEarthworks earthworks={props.earthworks} site={props.site}
        loading={props.loading} removeEarthwork={props.removeEarthwork}
        removeLocalEarthwork={props.removeLocalEarthwork} changeEarthwork={props.changeEarthwork} />
      <div className='row'>
        <div className='column'>
          <CurrentLocation roadNumber={props.roadNumber} roadPart={props.roadPart}
            roadPole={props.roadPole} loading={props.loading}
            accuracy={props.accuracy} time={props.time} />
        </div>
        <div className='column'>
          <CurrentData dailyMass={props.dailyMass} wholeMass={props.wholeMass} />
        </div>
      </div>
    </fieldset>
  );
};

const FiveLastEarthworks = props => {
  if (props.site == null) return null;
  if (props.earthworks.size === 0) return <p>Ei yhtään lisäystä</p>;
  if (props.loading) return <div className="loader"></div>;

  return (
    <div>
      <h4>5 viimeisintä maanajoa</h4>
      {props.earthworks.splice(0, props.earthworks.size - 5).map(earthwork => {
        const date = new Date(earthwork.get('date'));
        const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' +
          paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes());
        let header = [];

        header.push(<span key={earthwork.get('id')}>
          {time + ' | ' + earthwork.get('truck').get('register_number') +
            ' | Lastaus: ' + earthwork.get('road_part') + ' / ' + earthwork.get('road_distance') +
            ' | ' +
            (earthwork.get('banking_latitude') ? 'Läjitys' : (earthwork.get('bank_latitude') ? 'Penger' : 'Kippaamaton')) +
            ' | Massa: ' + earthwork.get('mass')}
        </span>);

        let loadtime = '-';

        if (earthwork.get('banking_date')) {
          const bankingDate = new Date(earthwork.get('banking_date'));
          loadtime = bankingDate.getDate() + '.' + (bankingDate.getMonth() + 1) + '.' + bankingDate.getFullYear() + ' ' +
            paddedNumber(bankingDate.getHours()) + ':' + paddedNumber(bankingDate.getMinutes());
        }
        else {
          const bankDate = new Date(earthwork.get('bank_date'));
          loadtime = bankDate.getDate() + '.' + (bankDate.getMonth() + 1) + '.' + bankDate.getFullYear() + ' ' +
            paddedNumber(bankDate.getHours()) + ':' + paddedNumber(bankDate.getMinutes());
        }

        let roadInfo = '-';

        if (earthwork.get('banking_road_part')) {
          roadInfo = earthwork.get('banking_road_part') + ' / ' + earthwork.get('banking_road_distance');
        }

        if (earthwork.get('bank_road_part')) {
          roadInfo = earthwork.get('bank_road_part') + ' / ' + earthwork.get('bank_road_distance');
        }

        let data = {
          'Huomiot': earthwork.get('attentions') || '-',
          'Kippaus aika': loadtime,
          'Kippaus tierekisteri': roadInfo,
        };

        if (earthwork.get('banking_name')) {
          data['Läjitysalue'] = earthwork.get('banking_name');
        }

        if (earthwork.get('not_saved')) {
          header.push(<span key={earthwork.get('id') + '-not_saved'}>
            {earthwork.get('not_saved') ? ' (Ei palvelimella)' : ''}
          </span>);
        }

        return (
          <div className='datafield' key={earthwork.get('id')}>
            <DataField header={header} data={data} class={earthwork.get('not_saved') ? 'yellow' : null}>
              <br />
              <div className='row center'>
                <div className='column'>
                  <button onClick={props.changeEarthwork.bind(this, earthwork)}>
                    Muokkaa
                  </button>
                </div>
                <div className='column'>
                  <button className='button-outline' onClick={earthwork.get('not_saved') ?
                    props.removeLocalEarthwork.bind(this, earthwork.get('id')) :
                    props.removeEarthwork.bind(this, earthwork.get('id'))}>
                    Poista
                  </button>
                </div>
              </div>
            </DataField>
          </div>
        );
      })
      }
    </div>
  );
};

const CurrentLocation = props => {
  let accuracyColor;
  let accuracy = props.accuracy;

  if (accuracy > 20) accuracyColor = 'red';
  else if (accuracy > 10) accuracyColor = 'yellow';
  else if (accuracyColor != null) accuracyColor = 'green';

  if (accuracy >= 10000) accuracy = '-';

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={3}>
            Nykyinen sijainti
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {'Tie: '}
            {props.roadNumber || '-'}
          </td>
          <td>
            {'Tieosa: '}
            {props.roadPart || '-'}
          </td>
          <td>
            {'Paalu: '}
            {props.roadPole || '-'}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            {'Päivitetty: '}
            {props.time || '-'}
          </td>
          <td>
            {'Tarkkuus: '}
            <span id={'location-accuracy-' + accuracyColor}>{accuracy || '-'} </span>m
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export const EarthworkNew = (properties) => {
  const props = properties;

  let watchID = null;
  let socket = useRef(null);
  let removingEarthwork;

  const [viewState, setViewState] = useState(0);
  const [roadPart, setRoadPart] = useState(null);
  const [roadDistance, setRoadDistance] = useState(null);
  const [mass, setMass] = useState(0);
  const [attentions, setAttentions] = useState('');
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [dailyCount, setDailyCount] = useState(0);
  const [dailyMass, setDailyMass] = useState(0);
  const [autoGPS, setAutoGPS] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [paths, setPaths] = useState({});
  const [bankingName, setBankingName] = useState('');
  const [useRoadInfoForBanking, setUseRoadInfoForBanking] = useState(false);
  const [creatingMacadam, setCreatingMacadam] = useState();
  const [externalGPS, setExternalGPS] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [accuracy, setAccuracy] = useState(null);
  const [locationTime, setLocationTime] = useState(null);
  const [location_road_number, setLocation_road_number] = useState();
  const [location_road_part, setLocation_road_part] = useState();
  const [location_road_pole, setLocation_road_pole] = useState();
  const [locationErrorState, setLocationErrorState] = useState();
  const [sensor, setSensor] = useState(null);
  const [deepnessSensor, setDeepnessSensor] = useState(null);
  const [accuracySubmit, setAccuracySubmit] = useState();
  const [showAccuracyFixer, setShowAccuracyFixer] = useState(null);
  const [callback, setCallback] = useState(null);
  const [loadingLoad, setLoadingLoad] = useState();
  const [showSetBanking, setShowSetBanking] = useState();
  const [loadingBank, setLoadingBank] = useState();
  const [loadingEarthworks, setLoadingEarthworks] = useState();
  const [changingEarthwork, setChangingEarthwork] = useState();
  const [changingMass, setChangingMass] = useState();
  const [wholeMass, setWholeMass] = useState();
  const [accuracyFixerCallback, setAccuracyFixerCallback] = useState();

  const [lastLoadPart, setLastLoadPart] = useState();
  const [lastLoadDistance, setLastLoadDistance] = useState();
  const [lastTruck, setLastTruck] = useState();
  const [lastMass, setLastMass] = useState();
  const [lastBankingName, setLastBankingName] = useState();
  const [lastBankingLatitude, setLastBankingLatitude] = useState();
  const [lastBankingLongitude, setLastBankingLongitude] = useState();
  const [lastBankLatitude, setLastBankLatitude] = useState();
  const [lastBankLongitude, setLastBankLongitude] = useState();

  useEffect(() => {
    if (localStorage['login'] == null) {
      return;
    }

    if (socket.current == null && typeof (WebSocket) !== 'undefined') {
      socket.current = Socket('/data/earthwork');
    }

    if (typeof (Storage) !== "undefined") {
      setRoadPart(integerValue(localStorage.roadPart, 0));
      setRoadDistance(integerValue(localStorage.roadDistance, 0));
      setMass(integerValue(localStorage.mass, 0));
      setAttentions(localStorage.attentions ? localStorage.attentions : '');
      setAutoGPS(localStorage.autoGPS === 'false' ? false : true);
      setBankingName(localStorage.bankingName ? localStorage.bankingName : '');
    }

    watchID = navigator.geolocation.watchPosition(setLocation, locationError,
      { enableHighAccuracy: true });

    return () => {
      navigator.geolocation.clearWatch(watchID);
      if (socket.current != null) socket.current.close();
    };
  }, [])

  useEffect(() => {
    if (autoGPS) {
      useLocation(true);
    }
  }, [autoGPS])

  useEffect(() => {
    getLastEarthworkData(props.earthworks, creatingMacadam);
    getDailyData(props.earthworks);
  }, [props.earthworks])

  useEffect(() => {
    if (props.selectedConstructionSite != null) {
      if (typeof(Storage) !== "undefined") {
      getEarthworks(props.selectedConstructionSite.get('id'));
    }

      socket.current.onmessage = function (e) {
        const data = JSON.parse(e.data);

        if (data['operation'] === 'create') {
          if (props.selectedConstructionSite.get('id') !== data.model.construction_site_id) {
            return;
          }
          props.addEarthwork(data.model);
        }
        else if (data['operation'] === 'update') {
          if (props.selectedConstructionSite.get('id') !== data.model.construction_site_id) {
            return;
          }
          props.changeEarthwork(data.model);
        }
        else if (data['operation'] === 'delete') {
          props.removeEarthwork(data.model.id);
        }
      }.bind(this);
    }

    if (props.selectedContract == null || props.selectedConstructionSite == null) {
      props.clearEarthworks();
      return;
    }
  }, [props.selectedContract, props.selectedConstructionSite])

  useEffect(() => {
    if (props.selectedTruck != null) {
      setMass(props.selectedTruck.get('default_mass'));
    }
  }, [props.selectedTruck])

  useEffect(() => {
    if (props.selectedConstructionSite != null) {
      getEarthworks(props.selectedConstructionSite.get('id'));
    }
  }, [props.selectedConstructionSite])

  const changeState = (propertyName, type, defaultValue, event) => {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }

    if (propertyName === 'autoGPS') {
      setAutoGPS(value);
    }
    if (propertyName === 'roadPart') {
      setRoadPart(value);
    }
    if (propertyName === 'roadDistance') {
      setRoadDistance(value);
    }
    if (propertyName === 'mass') {
      setMass(value);
    }
    if (propertyName === 'quality') {
      setQuality(value);
    }
    if (propertyName === 'attentions') {
      setAttentions(value);
    }
    if (propertyName === 'bankingName') {
      setBankingName(value);
    }
    if (propertyName === 'useRoadInfoForBanking') {
      setUseRoadInfoForBanking(value);
    }

    if (typeof (Storage) !== 'undefined') {
      localStorage[propertyName] = value;
    }
  }

  const setLocation = async (position) => {
    let latitude = null;
    let longitude = null;
    let accuracy = null;

    if (position.coords) {
      if (externalGPS) {
        return;
      }

      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      accuracy = Math.ceil(position.coords.accuracy);
    }
    else {
      latitude = position.lat;
      longitude = position.lon;
    }

    const time = new Date();

    setLatitude(latitude);
    setLongitude(longitude);
    setAccuracy(accuracy);
    setLocationTime(paddedNumber(time.getHours()) + ':' + paddedNumber(time.getMinutes()) + ':' + paddedNumber(time.getSeconds()));

    let road;

    if (props.selectedConstructionSite != null) {
      road = props.selectedConstructionSite.get('road_number');
    }

    const converted = toETRSTM35FIN(latitude, longitude);
    const data = await getRoadData(converted.y, converted.x, accuracy, road);

    if (data != null) {
      setLocationErrorState(false);
      setLocation_road_number(data.road);
      setLocation_road_part(data.part);
      setLocation_road_pole(data.distance);
    }
    else {
      const data = await getRoadData(converted.y, converted.x, accuracy);

      if (data != null) {
        setLocationErrorState(false);
        setLocation_road_number(data.road);
        setLocation_road_part(data.part);
        setLocation_road_pole(data.distance);
      }
      else {
        setLocationErrorState(true);
        setLocation_road_number(null);
        setLocation_road_part(null);
        setLocation_road_pole(null);
      }
    }


    if (sensor && deepnessSensor) {
      getDeepnessSensorValue();
    }
  }

  useEffect(() => {
    if (locationErrorState === false) {
      useLocation(autoGPS);
    }
  }, [locationErrorState, location_road_number, location_road_part, location_road_pole])

  const useLocation = async (autoGPS) => {
    const roadNumber = location_road_number;

    if (props.selectedConstructionSite &&
      props.selectedConstructionSite.get('road_number') !== roadNumber) {
      return;
    }

    if (!autoGPS) return;

    setRoadPart(location_road_part || '');
    setRoadDistance(location_road_pole || '');
  }

  useEffect(() => {
    if (showAccuracyFixer && accuracy < 20) {
      accuracySubmit();
    }
    localStorage.roadPart = roadPart;
    localStorage.roadDistance = roadDistance;
  }, [roadPart, roadDistance])

  const locationError = (err) => {
    props.showMessage('Virhe', 'ERROR(' + err.code + '): ' + err.message, 'Error');
  }

  const saveEarthwork = (earthwork) => {
    if (typeof (Storage) !== 'undefined') {
      if (localStorage['savedEarthworks'] == null) {
        localStorage['savedEarthworks'] = JSON.stringify([]);
      }

      earthwork.id = Date.now();
      earthwork.not_saved = true;

      let earthworks = JSON.parse(localStorage['savedEarthworks']);
      earthworks.push(earthwork);
      localStorage['savedEarthworks'] = JSON.stringify(earthworks);

      earthwork.date = earthwork.date + 'Z';
      props.addEarthwork(earthwork);
    }
    else {
      props.showMessage('Virhe', 'Maanajoa ei voitu tallentaa paikallisesti eikä palvelimelle', 'Error');
    }
  }

  const saveUpdatedEarthwork = (earthwork) => {
    if (typeof (Storage) !== 'undefined') {
      if (localStorage['updatedEarthwork'] == null) {
        localStorage['updatedEarthwork'] = JSON.stringify([]);
      }

      let earthworks = JSON.parse(localStorage['updatedEarthwork']);
      let existingEarthworkIndex = earthworks.findIndex(e => e.id === earthwork.id);

      if (existingEarthworkIndex) {
        earthworks[existingEarthworkIndex] = earthwork;
      }
      else {
        earthworks.push(earthwork);
      }

      localStorage['updatedEarthwork'] = JSON.stringify(earthworks);

      const currentIndex = props.earthworks.findIndex(e => e.get('id') === earthwork.id);
      const currentEarthwork = props.earthworks.get(currentIndex);
      let oldValues = {};

      oldValues.road = currentEarthwork.get('road');
      oldValues.banking_latitude = currentEarthwork.get('banking_latitude');
      oldValues.construction_site_id = currentEarthwork.get('construction_site_id');
      oldValues.latitude = currentEarthwork.get('latitude');
      oldValues.bank_longitude = currentEarthwork.get('bank_longitude');
      oldValues.date = currentEarthwork.get('date');
      oldValues.banking_date = currentEarthwork.get('banking_date');
      oldValues.back_date = currentEarthwork.get('back_date');
      oldValues.longitude = currentEarthwork.get('longitude');
      oldValues.bank_latitude = currentEarthwork.get('bank_latitude');
      oldValues.bank_road_part = currentEarthwork.get('bank_road_part');
      oldValues.bank_road_distance = currentEarthwork.get('bank_road_distance');
      oldValues.road_part = currentEarthwork.get('road_part');
      oldValues.road_distance = currentEarthwork.get('road_distance');
      oldValues.attentions = currentEarthwork.get('attentions');
      oldValues.banking_longitude = currentEarthwork.get('banking_longitude');
      oldValues.banking_road_part = currentEarthwork.get('banking_road_part');
      oldValues.banking_road_distance = currentEarthwork.get('banking_road_distance');
      oldValues.truck = currentEarthwork.get('truck');

      earthwork = Object.assign({}, oldValues, earthwork);

      earthwork.date = earthwork.date + 'Z';
      earthwork.banking_date = earthwork.banking_date ? earthwork.banking_date + 'Z' : null;
      earthwork.back_date = earthwork.back_date ? earthwork.back_date + 'Z' : null;

      props.changeEarthwork(earthwork);
    }
    else {
      props.showMessage('Virhe', 'Maanajoa ei voitu tallentaa paikallisesti eikä palvelimelle', 'Error');
    }
  }

  const sendSavedEarthworks = async () => {
    let earthworks = JSON.parse(localStorage['savedEarthworks']);
    let newEarthworks = earthworks.slice();
    let error = false;

    for (let index in earthworks) {
      const earthwork = earthworks[index];

      try {
        await fetch('/earthwork', 'POST', earthwork);
        newEarthworks.splice(newEarthworks.findIndex(newEarthwork => newEarthwork['id'] === earthwork['id']), 1);
      } catch (err) {
        console.log(err);
        error = true;
      }
    }

    localStorage['savedEarthworks'] = JSON.stringify(newEarthworks);
    getEarthworks(props.selectedConstructionSite.get('id'), error);
  }

  const sendUpdatedEarthworks = async () => {
    let earthworks = JSON.parse(localStorage['updatedEarthwork']);
    let newEarthworks = earthworks.slice();
    let error = false;

    for (let index in earthworks) {
      const earthwork = earthworks[index];

      try {
        await fetch('/earthwork' + earthwork.id, 'PATCH', earthwork);
        newEarthworks.splice(newEarthworks.findIndex(newEarthwork => newEarthwork['id'] === earthwork['id']), 1);
      } catch (err) {
        console.log(err);
        error = true;
      }
    }

    localStorage['updatedEarthwork'] = JSON.stringify(newEarthworks);
    getEarthworks(props.selectedConstructionSite.get('id'), error);
  }

  const confirmRoadInfo = (callback) => {
    setCallback(() => callback);
    setConfirmed(true);
  }

  useEffect(() => {
    if (callback != null) {
      callback();
      setCallback(null);
    }
  }, [confirmed])

  const onLoad = async () => {
    if (props.selectedConstructionSite == null) {
      props.showNotice('Kohdetta ei ole valittu', 'Warning')
      return;
    }

    if (roadPart === 0 || roadPart == null || roadPart === '') {
      props.showNotice('Tieosaa ei ole annettu', 'Warning')
      return;
    }

    if (roadDistance == null || roadDistance === '') {
      props.showNotice('Paalua ei ole annettu', 'Warning')
      return;
    }

    if (props.selectedTruck == null) {
      props.showNotice('Rekkaa ei ole valittu', 'Warning')
      return;
    }

    if (mass == null) {
      props.showNotice('Rekkaa ei ole valittu', 'Warning')
      return;
    }

    if (!confirmed) {
      if (!showAccuracyFixer) {
        if (!showAccuracyFixer && autoGPS && accuracy >= 20) {
          toggleAccuracyFixer(onLoad);
          return;
        }
      }
      else {
        setShowAccuracyFixer(false);
      }
    }

    const roadNumber = props.selectedConstructionSite.get('road_number');
    const roadPart1 = roadPart;
    const roadDistance1 = roadDistance;

    try {
      if (!confirmed && !(await testIsValidRoadInfo(roadNumber, roadPart1, roadDistance1))) {
        props.showConfirm(roadNumber + '/' + roadPart1 + '/' + roadDistance1 +
          ' Ei ole oikea tierekisteri. Haluatko jatkaa?', confirmRoadInfo.bind(null, onLoad));
        return;
      }
    } catch (error) {
      console.log(error);
    }

    setConfirmed(false);
    setLoadingLocation(true);

    const date = new Date();
    date.setHours(date.getHours());

    const earthwork = {
      construction_site_id: props.selectedConstructionSite.get('id'),
      road: roadNumber,
      road_part: roadPart1,
      road_distance: roadDistance1,
      latitude: latitude,
      longitude: longitude,
      date: date.toISOString().replace('Z', ''),
      truck_id: props.selectedTruck.get('id'),
      truck: { register_number: props.selectedTruck.get('register_number') },
      mass: mass,
      attentions: attentions
    };

    try {
      const newEarthwork = await fetch('/earthwork', 'POST', earthwork);
      props.addEarthwork(newEarthwork);
    } catch (error) {
      saveEarthwork(earthwork);
    }

    props.showNotice('Kuorma lastattu', 'Ok');
    setLoadingLoad(false);
  }

  const toggleSetBanking = () => {
    setShowSetBanking(!showSetBanking);
  }

  const onBanking = async () => {
    if (bankingName === '') {
      props.showNotice('Nimeä ei ole annettu', 'Warning')
      return;
    }

    let roadNumber = props.selectedConstructionSite.get('road_number');
    let roadPart1 = null;
    let roadDistance1 = null;

    if (useRoadInfoForBanking) {
      if (roadPart === 0 || roadPart == null || roadPart === '') {
        props.showNotice('Tieosaa ei ole annettu', 'Warning')
        return;
      }

      if (roadDistance == null || roadDistance === '') {
        props.showNotice('Paalua ei ole annettu', 'Warning')
        return;
      }

      if (!confirmed) {
        if (!showAccuracyFixer) {
          if (!showAccuracyFixer && autoGPS && accuracy >= 20) {
            toggleAccuracyFixer(onBanking);
            return;
          }
        }
        else {
          setShowAccuracyFixer(false);
        }
      }

      roadPart1 = roadPart;
      roadDistance1 = roadDistance;

      try {
        if (!confirmed && !(await testIsValidRoadInfo(roadNumber, roadPart1, roadDistance1))) {
          props.showConfirm(roadNumber + '/' + roadPart + '/' + roadDistance +
            ' Ei ole oikea tierekisteri. Haluatko jatkaa?', confirmRoadInfo.bind(null, onBanking));
          return;
        }
      } catch (error) {
        console.log(error);
      }

      setConfirmed(false);
    }

    const date = new Date();
    date.setHours(date.getHours());

    const earthwork = {
      id: props.earthworks.last().get('id'),
      banking_name: bankingName,
      banking_road_part: roadPart1,
      banking_road_distance: roadDistance1,
      banking_latitude: latitude,
      banking_longitude: longitude,
      banking_date: date.toISOString().replace('Z', ''),
    };

    try {
      const changedEarthwork = await fetch('/earthwork/' + earthwork.id,
        'PATCH', earthwork);
      props.changeEarthwork(changedEarthwork);
    } catch (error) {
      saveUpdatedEarthwork(earthwork);
    }

    props.showNotice('Läjitys kipattu', 'Ok');
    toggleSetBanking();
  }

  const onBank = async () => {
    if (roadPart === 0 || roadPart == null || roadPart === '') {
      props.showNotice('Tieosaa ei ole annettu', 'Warning')
      return;
    }

    if (roadDistance == null || roadDistance === '') {
      props.showNotice('Paalua ei ole annettu', 'Warning')
      return;
    }

    if (!confirmed) {
      if (!showAccuracyFixer) {
        if (!showAccuracyFixer && autoGPS && accuracy >= 20) {
          toggleAccuracyFixer(onBank);
          return;
        }
      }
      else {
        setShowAccuracyFixer(false);
      }
    }

    const roadNumber = props.selectedConstructionSite.get('road_number');
    const roadPart1 = roadPart;
    const roadDistance1 = roadDistance;

    try {
      if (!confirmed && !(await testIsValidRoadInfo(roadNumber, roadPart1, roadDistance1))) {
        props.showConfirm(roadNumber + '/' + roadPart1 + '/' + roadDistance1 +
          ' Ei ole oikea tierekisteri. Haluatko jatkaa?', confirmRoadInfo.bind(null, onBank));
        return;
      }
    } catch (error) {
      console.log(error);
    }

    setConfirmed(false);
    setLoadingBank(true);

    const date = new Date();
    date.setHours(date.getHours());

    const earthwork = {
      id: props.earthworks.last().get('id'),
      bank_road_part: roadPart1,
      bank_road_distance: roadDistance1,
      bank_latitude: latitude,
      bank_longitude: longitude,
      bank_date: date.toISOString().replace('Z', ''),
    };

    try {
      const changedEarthwork = await fetch('/earthwork/' + earthwork.id,
        'PATCH', earthwork);
      props.changeEarthwork(changedEarthwork);
    } catch (error) {
      saveUpdatedEarthwork(earthwork);
    }

    props.showNotice('Penger kipattu', 'Ok');

    setLoadingBank(false);
  }

  const getEarthworks = async (constructionSite, error = false) => {
    setLoadingEarthworks(true);

    if (!error && typeof (Storage) !== 'undefined') {
      if (localStorage['savedEarthworks'] != null &&
        JSON.parse(localStorage['savedEarthworks']).length !== 0) {
        await sendSavedEarthworks();
        return;
      }
      if (localStorage['updatedEarthworks'] != null &&
        JSON.parse(localStorage['updatedEarthworks']).length !== 0) {
        await sendUpdatedEarthworks();
        return;
      }
    }

    fetch('/earthwork?site=' + constructionSite).then(async data => {
      if (localStorage['savedEarthworks'] != null && JSON.parse(localStorage['savedEarthworks']).length !== 0) {
        const savedEarthworks = JSON.parse(localStorage['savedEarthworks']).filter(
          macadam => macadam.construction_site_id === props.selectedConstructionSite.get('id'));
        data = data.concat(savedEarthworks);
      }

      if (localStorage['updatedEarthworks'] != null && JSON.parse(localStorage['updatedEarthworks']).length !== 0) {
        const updatedEarthworks = JSON.parse(localStorage['updatedEarthworks']).filter(
          macadam => macadam.construction_site_id === props.selectedConstructionSite.get('id'));
        updatedEarthworks.forEach(macadam => {
          const index = data.findIndex(d => d.id === macadam.id);
          if (index !== -1) data.splice(index, 1);
          data.push(macadam);
        });
      }

      props.addEarthworks(data);
    }).catch(error => {
      console.log(error);
      let earthworks = [];

      if (localStorage['savedEarthworks'] != null && JSON.parse(localStorage['savedEarthworks']).length !== 0) {
        const savedEarthworks = JSON.parse(localStorage['savedEarthworks']).filter(
          earthwork => earthwork.construction_site_id === props.selectedConstructionSite.get('id'));
        earthworks = earthworks.concat(savedEarthworks);
      }

      if (localStorage['updatedEarthworks'] != null && JSON.parse(localStorage['updatedEarthworks']).length !== 0) {
        const updatedEarthworks = JSON.parse(localStorage['updatedEarthworks']).filter(
          earthwork => earthwork.construction_site_id === props.selectedConstructionSite.get('id'));
        updatedEarthworks.forEach(earthwork => {
          const index = earthworks.findIndex(d => d.id === earthwork.id);
          if (index !== -1) earthworks.splice(index, 1);
          earthworks.push(earthwork);
        });
      }

      props.addEarthworks(earthworks);
    })
      .then(() => {
        setLoadingEarthworks(false);
      })
  }

  const goChangeEarthwork = (earthworkId) => {
    setChangingEarthwork(earthworkId);
  }

  const clearChangeEarthwork = () => {
    setChangingEarthwork(null);
  }

  const changeEarthwork = (earthwork) => {
    fetch('/earthwork/' + earthwork.get('id'), 'PATCH', earthwork).then(data => {
      props.showNotice('Maanajo muokattu', 'Ok');
      props.changeMacadam(data);
      clearChangeEarthwork();
    }).catch(() => {
      saveUpdatedEarthwork(earthwork);
      clearChangeEarthwork();
    });
  }

  const changeLocalEarthwork = (earthwork) => {
    let earthworks = JSON.parse(localStorage['savedEarthwork']);
    const index = earthworks.findIndex(earthwork => earthwork['id'] === changingMass.get('id'));
    earthworks[index] = earthwork;
    localStorage['savedEarthwork'] = JSON.stringify(earthworks);
    props.showNotice('Maanajo muokattu', 'Ok');
    props.changeEarthwork(earthwork);
    clearChangeEarthwork();
  }

  const confirmRemoveEarthwork = (earthworkId) => {
    removingEarthwork = earthworkId;
    props.showConfirm('Poistetaanko maanajo?', removeEarthwork);
  }

  const removeEarthwork = () => {
    fetch('/earthwork/' + removingEarthwork + '/', 'DELETE').then(() => {
      props.showNotice('Maanajo poistettu', 'Ok')
      props.removeEarthwork(removingEarthwork);
      removingEarthwork = null;
    }).catch(() => {
      props.showMessage('Virhe', 'Maanajon poisto epäonnistui', 'Error');
      removingEarthwork = null;
    });
  }

  const removeLocalEarthwork = () => {
    // Without timer does not work. Error: (Reducers may not dispatch actions.)
    timer(0).then(() => {
      let earthworks = JSON.parse(localStorage['savedEarthwork']);
      earthworks = earthworks.filter(earthwork => earthwork['id'] !== removingEarthwork);
      localStorage['savedEarthwork'] = JSON.stringify(earthworks);
      props.showNotice('Maanajo poistettu', 'Ok')
      props.removeMacadam(removingEarthwork);
    });
  }

  const confirmRemoveLocalEarthwork = (earthworkId) => {
    removingEarthwork = earthworkId;
    props.showConfirm('Poistetaanko maanajo?', removeLocalEarthwork);
  }


  const getDailyData = (earthworks) => {
    let dailyCount = 0;
    let dailyMass = 0;
    let wholeMass = 0;
    let now = new Date();

    now.setHours(now.getHours());

    let past12HoursFromNow = new Date(now);
    past12HoursFromNow.setHours(past12HoursFromNow.getHours() - 12);

    let newDay = false;

    for (let i = earthworks.size - 1; i >= 0; i--) {
      const earthwork = earthworks.get(i);
      const date = new Date(earthwork.get('date'));

      if (!newDay && date >= past12HoursFromNow &&
        date <= now) {
        dailyCount++;
        dailyMass += parseFloat(earthwork.get('mass'));
      }

      if (i !== 0) {
        let past6Hours = new Date(date);
        past6Hours.setHours(past6Hours.getHours() - 6);
        let beforeEarthworkDate = new Date(earthworks.get(i - 1).get('date'));
        beforeEarthworkDate.setHours(beforeEarthworkDate.getHours());
        if (past6Hours >= beforeEarthworkDate) {
          newDay = true;
        }
      }

      wholeMass += parseFloat(earthwork.get('mass'));
    }

    setDailyCount(dailyCount);
    setDailyMass(dailyMass);
    setWholeMass(wholeMass);
  }

  const setView = (view) => {
    setViewState(view);
  }

  const toggleAccuracyFixer = (callback) => {
    setShowAccuracyFixer(!showAccuracyFixer);
    setAccuracyFixerCallback(callback);
  }

  const getLastEarthworkData = (earthworks) => {
    let lastLoadPart = 0;
    let lastLoadDistance = 0;
    let lastTruck = null;
    let lastMass = null;
    let lastBankingName = null;
    let lastBankingLatitude = null;
    let lastBankingLongitude = null;
    let lastBankLatitude = null;
    let lastBankLongitude = null;

    const lastEarthwork = earthworks.last();

    if (lastEarthwork != null) {
      lastLoadPart = lastEarthwork.get('road_part');
      lastLoadDistance = lastEarthwork.get('road_distance');
      lastTruck = lastEarthwork.get('truck').get('register_number');
      lastMass = lastEarthwork.get('mass');
      lastBankingName = lastEarthwork.get('banking_name');
      lastBankingLatitude = lastEarthwork.get('banking_latitude');
      lastBankingLongitude = lastEarthwork.get('banking_longitude');
      lastBankLatitude = lastEarthwork.get('bank_latitude');
      lastBankLongitude = lastEarthwork.get('bank_longitude');
    }

    setLastLoadPart(lastLoadPart);
    setLastLoadDistance(lastLoadDistance);
    setLastTruck(lastTruck);
    setLastMass(lastMass);
    setLastBankingName(lastBankingName);
    setLastBankingLatitude(lastBankingLatitude);
    setLastBankingLongitude(lastBankingLongitude);
    setLastBankLatitude(lastBankLatitude)
    setLastBankLongitude(lastBankLongitude);
  }

  return (
    <div>
      <TopContractAndSiteSelect store={props.store} />
      <div className="container">
        <h1>Maanajo</h1>
        <div className='button-area'>
          <button className='button-view' onClick={setView.bind(this, 0)}
            disabled={viewState === 0}>
            Lisää
          </button>
          <button className='button-view' onClick={setView.bind(this, 2)}
            disabled={viewState === 2}>
            Seurantatiedot
          </button>
        </div>
        <View view={viewState} changeState={changeState} roadPart={roadPart}
          roadDistance={roadDistance}
          road={props.selectedConstructionSite ? props.selectedConstructionSite.get('road_number') : '-'}
          onLoad={onLoad} useLocation={useLocation}
          mass={mass}
          attentions={attentions}
          dailyMass={dailyMass}
          dailyCount={dailyCount} wholeMass={wholeMass}
          earthworks={props.earthworks} selectedConstructionSite={props.selectedConstructionSite}
          loading={loadingEarthworks} confirmRemoveEarthwork={confirmRemoveEarthwork}
          confirmRemoveLocalEarthwork={confirmRemoveLocalEarthwork}
          location_road_number={location_road_number} location_road_part={location_road_part}
          location_road_pole={location_road_pole} accuracy={accuracy}
          locationTime={locationTime} latitude={latitude} longitude={longitude}
          showMessage={props.showMessage} changeEarthwork={goChangeEarthwork}
          autoGPS={autoGPS}
          loadingLoad={loadingLoad}
          loadingBank={loadingBank}
          lastLoadPart={lastLoadPart}
          lastLoadDistance={lastLoadDistance}
          lastMass={lastMass}
          lastTruck={lastTruck}
          toggleSetBanking={toggleSetBanking}
          onBank={onBank}
          lastBankLatitude={lastBankLatitude}
          lastBankingLatitude={lastBankingLatitude}
          store={props.store}
        />
        <ChangeEarthworkView earthwork={changingEarthwork} clear={clearChangeEarthwork}
          changeEarthwork={changeEarthwork} changeLocalEarthwork={changeLocalEarthwork} />
        <SetBankingName show={showSetBanking} toggle={toggleSetBanking}
          submit={onBanking} bankingName={bankingName}
          useRoadInfoForBanking={useRoadInfoForBanking} changeState={changeState} />
        <AccuracyFixer show={showAccuracyFixer} toggle={toggleAccuracyFixer}
          submit={accuracyFixerCallback} accuracy={accuracy} />
      </div>
    </div>
  );
}

export default connect(state => ({
  earthworks: state.earthwork.get('earthworks'),
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  selectedTruck: state.truckSelect.get('selectedTruck'),
}), {
  clearEarthworks, addEarthworks, removeEarthwork, changeEarthwork, showNotice, showConfirm,
  selectTruck, showMessage, addEarthwork
})(EarthworkNew);
